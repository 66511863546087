<template>
  <div class="background">
    <ServiceCategories />
  </div>
</template>

<script>
import ServiceCategories from "@/components/elements/dashboard/service-categories/ServiceCategories";

export default {
  name: "DocumentManagementServiceCategories",

  components: {
    ServiceCategories,
  },

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped></style>
