<template>
  <div>
    <v-card class="pa-4 mt-5 pa-lg-7" elevation="1" rounded="lg">
      <v-card flat>
        <v-data-table
          :headers="headers"
          :items="serviceCategories"
          sort-by="id"
          :sort-desc="true"
          class="elevation-1"
          :page.sync="page"
          :items-per-page="serviceCategoriesPerPage"
          hide-default-footer
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Service Categories</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" persistent max-width="500px">
                <template
                  v-if="$can('service_category_create')"
                  v-slot:activator="{ on, attrs }"
                >
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    New Service Category
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-form ref="form" lazy-validation>
                        <v-row>
                          <!-- Name -->
                          <v-col class="mt-n5" cols="12">
                            <v-text-field
                              v-model="editedServiceCategory.name"
                              :rules="nameRules"
                              label="Name"
                              required
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  Name <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-text-field>
                          </v-col>

                          <!-- Description -->
                          <v-col class="mt-n5" cols="12">
                            <v-textarea
                              v-model="editedServiceCategory.description"
                              :rules="descriptionRules"
                              label="Description"
                            >
                              <template v-slot:label>
                                <p class="body-1">Description</p>
                              </template>
                            </v-textarea>
                          </v-col>

                          <!-- Ative -->
                          <v-col class="mt-n5" cols="12">
                            <v-checkbox
                              v-model="editedServiceCategory.active"
                              :label="`Active`"
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="btn btn-primary" @click="close">
                      Cancel
                    </v-btn>
                    <v-btn color="btn btn-primary" @click="save"> Save </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5">
                    Are you sure you want to delete this service category?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="btn btn-primary" @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn
                      color="btn btn-primary"
                      @click="deleteServiceCategoryConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>

          <!-- active -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.active="{ item }">
            <v-chip
              v-if="item.active"
              class="ma-2"
              color="green"
              text-color="white"
            >
              Yes
            </v-chip>

            <v-chip v-else class="ma-2" color="red" text-color="white">
              No
            </v-chip>
          </template>

          <!-- created_at -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.created_at="{ item }">
            {{ $helpers.datetime(item.created_at) }}
          </template>

          <!-- updated_at -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.updated_at="{ item }">
            {{ $helpers.datetime(item.updated_at) }}
          </template>

          <!-- actions -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.actions="{ item }">
            <v-icon
              v-if="$can('service_category_update')"
              small
              class="mr-2"
              @click="editServiceCategory(item)"
              color="primary"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              v-if="$can('service_category_delete')"
              small
              @click="deleteServiceCategory(item)"
              color="red"
            >
              mdi-delete
            </v-icon>
          </template>

          <template v-slot:no-data>
            <v-btn color="primary"> No Data Available </v-btn>
          </template>
        </v-data-table>

        <v-row class="text-center px-4 align-center mt-4" wrap>
          <v-col class="text-truncate" cols="12" md="2">
            Total {{ totalRecords }} records
          </v-col>

          <v-col cols="12" md="6">
            <v-pagination v-model="page" :length="pageCount"> </v-pagination>
          </v-col>

          <v-col cols="6" md="3">
            <v-select
              v-model="perPageChoice"
              label="Service Categories per page"
              item-text="text"
              item-value="value"
              :items="perPageChoices"
              dense
              outlined
              hide-details
              return-object
            >
            </v-select>
          </v-col>
        </v-row>
      </v-card>

      <!-- Overlay -->
      <v-overlay :value="overlay" z-index="1000">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-card>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "DocumentManagementServiceCategories",

  props: {
    redirect: {
      default: "",
      type: String,
    },
  },

  data() {
    return {
      overlay: false,

      dialog: false,
      dialogDelete: false,
      headers: [
        { text: "Id", align: "start", value: "id" },
        { text: "Name", value: "name" },
        { text: "Description", value: "description" },
        { text: "Active", value: "active" },
        { text: "Created At", value: "created_at" },
        { text: "Updated At", value: "updated_at" },
        { text: "Actions", value: "actions", sortable: false },
      ],

      serviceCategories: [],

      editedIndex: -1,
      editedServiceCategory: {
        id: 0,
        name: "",
        description: "",
        active: true,
        created_at: "",
        updated_at: "",
      },
      defaultServiceCategory: {
        id: 0,
        name: "",
        description: "",
        active: true,
        created_at: "",
        updated_at: "",
      },

      // name
      nameRules: [
        (v) => !!v || "Required",
        (v) =>
          /^[a-zA-Z][a-zA-Z0-9 \-']+$/.test(v) ||
          "Only english letters, apostrophes and hyphens are allowed",
        (v) => v.length >= 3 || "Can not be less than 3 characters",
        (v) => v.length <= 100 || "Can not be more than 100 characters",
      ],

      // description
      descriptionRules: [],

      page: 1,
      serviceCategoriesPerPage: 100,
      totalRecords: 0,
      pageCount: 0,
      perPageChoice: { text: "100 records/page", value: 100 },
      perPageChoices: [
        { text: "5 records/page", value: 5 },
        { text: "25 records/page", value: 25 },
        { text: "50 records/page", value: 50 },
        { text: "100 records/page", value: 100 },
        { text: "150 records/page", value: 150 },
        { text: "200 records/page", value: 200 },
      ],
    };
  },

  computed: {
    ...mapState({ auth: "auth" }),

    // formTitle
    formTitle() {
      return this.editedIndex === -1
        ? "New Service Category"
        : "Edit Service Category";
    },
  },

  watch: {
    // dialog
    dialog(val) {
      val || this.close();
    },

    // dialogDelete
    dialogDelete(val) {
      val || this.closeDelete();
    },

    // page
    page() {
      this.getApiPagedServiceCategories();
    },

    // serviceCategoriesPerPage
    serviceCategoriesPerPage() {
      this.getApiPagedServiceCategories();
    },

    // perPageChoice
    perPageChoice(val) {
      this.serviceCategoriesPerPage = val.value;
      this.page = 1;
    },
  },

  created() {
    if (!this.$can("service_category_access")) {
      this.$router.push({ name: "DashboardInbox" });
    }

    // this.getApiServiceCategories();
    this.getApiPagedServiceCategories();
  },

  methods: {
    ...mapMutations({
      resetAuthState: "auth/resetState",
    }),

    // validateForm
    validateForm() {
      this.$refs.form.validate();

      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          this.updateApiServiceCategory();
        } else {
          this.createApiServiceCategory();
        }
      }
    },

    // editServiceCategory
    editServiceCategory(serviceCategory) {
      this.editedIndex = this.serviceCategories.indexOf(serviceCategory);
      this.editedServiceCategory = Object.assign({}, serviceCategory);
      this.dialog = true;
    },

    // deleteServiceCategory
    deleteServiceCategory(serviceCategory) {
      this.editedIndex = this.serviceCategories.indexOf(serviceCategory);
      this.editedServiceCategory = Object.assign({}, serviceCategory);
      this.dialogDelete = true;
    },

    // deleteServiceCategoryConfirm
    deleteServiceCategoryConfirm() {
      this.deleteApiServiceCategory();
    },

    // close
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedServiceCategory = Object.assign(
          {},
          this.defaultServiceCategory
        );
        this.editedIndex = -1;
      });

      this.$refs.form.resetValidation();
    },

    // closeDelete
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedServiceCategory = Object.assign(
          {},
          this.defaultServiceCategory
        );
        this.editedIndex = -1;
      });
    },

    // save
    save() {
      this.validateForm();
    },

    // getApiServiceCategories
    async getApiServiceCategories() {
      this.overlay = true;

      const res = await this.$helpers.getApiData("service-categories");

      if (res.status == 200) {
        this.serviceCategories = res.data;
      }

      this.overlay = false;
    },

    // getApiPagedServiceCategories
    async getApiPagedServiceCategories() {
      this.overlay = true;

      const res = await this.$helpers.getApiData(
        `service-categories/paged?page_no=${this.page}&page_size=${this.serviceCategoriesPerPage}`
      );

      if (res.status == 200) {
        this.serviceCategories = res.data.data;
        this.totalRecords = res.data.item_count;
        this.pageCount = res.data.page_count;

        this.$vuetify.goTo(0);
      }

      this.overlay = false;
    },

    // createApiServiceCategory
    async createApiServiceCategory() {
      this.overlay = true;

      var reqData = {
        name: this.editedServiceCategory.name,
        description: this.editedServiceCategory.description,
        active: this.editedServiceCategory.active,
      };

      const res = await this.$helpers.createApiData(
        "service-categories",
        reqData,
        "Service Category Created Successfully!"
      );

      if (res.status == 200) {
        this.serviceCategories.push(res.data);

        this.close();
      }

      this.overlay = false;
    },

    // updateApiServiceCategory
    async updateApiServiceCategory() {
      this.overlay = true;

      var reqData = {
        id: this.editedServiceCategory.id,
        name: this.editedServiceCategory.name,
        description: this.editedServiceCategory.description,
        active: this.editedServiceCategory.active,
      };

      const res = await this.$helpers.updateApiData(
        `service-categories/${this.editedServiceCategory.id}`,
        reqData,
        "Service Category Updated Successfully!"
      );

      if (res.status == 200) {
        Object.assign(this.serviceCategories[this.editedIndex], res.data);

        this.close();
      }

      this.overlay = false;
    },

    // deleteApiServiceCategory
    async deleteApiServiceCategory() {
      this.overlay = true;

      const res = await this.$helpers.deleteApiData(
        `service-categories/${this.editedServiceCategory.id}`,
        "Service Category Deleted Successfully!"
      );

      if (res.status == 200) {
        this.serviceCategories.splice(this.editedIndex, 1);

        this.closeDelete();
      }

      this.overlay = false;
    },
  },
};
</script>

<style lang="scss" scoped>
//
</style>
